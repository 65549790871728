import {
    IonIcon,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs
} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router";
import React, {useEffect, lazy, useRef, useState} from "react";
import API from "../api/Calls";
import {App} from "@capacitor/app";
import {useGlobalState} from "../App";
import {Route} from 'react-router-dom';
import {TabRoutes} from "../classes/TabRoutes";

const Welcome = lazy(() => import("./../pages/church/Welcome"));
const Actual = lazy(() => import("../pages/church/actual/Actual"));
const Financial = lazy(() => import('../pages/church/financial/Financial'));
const Community = lazy(() => import("../pages/church/community/Community"));
const More = lazy(() => import('../pages/church/more/More'));
const AccountDetails = lazy(() => import('../pages/church/more/account/Detail'));
const EditAccountDetails = lazy(() => import('../pages/church/more/account/EditDetail'));
const ChangePassword = lazy(() => import('../pages/church/more/account/ChangePassword'));
const BalanceSettings = lazy(() => import('../pages/church/more/settings/BalanceSettings'));
const Settings = lazy(() => import('../pages/church/more/settings/Settings'));
const BankAccounts = lazy(() => import('../pages/church/more/account/bankaccount/BankAccounts'));
const AddBankAccount = lazy(() => import('../pages/church/more/account/bankaccount/AddBankAccount'));
const BankAccountDetails = lazy(() => import('../pages/church/more/account/bankaccount/Detail'));
const EditBankAccountDetails = lazy(() => import('../pages/church/more/account/bankaccount/EditDetail'));
const AppInfo = lazy(() => import('../pages/church/more/about/AppInfo'));
const BalanceHistory = lazy(() => import('../pages/church/more/history/balance/History'));
const BalanceDetails = lazy(() => import('../pages/church/more/history/balance/Detail'));
const OrderHistory = lazy(() => import('../pages/church/more/history/order/History'));
const ContributionHistory = lazy(() => import('../pages/church/more/history/contribution/History'));
const DonationHistory = lazy(() => import('../pages/church/more/history/donation/History'));
const CommitmentHistory = lazy(() => import('../pages/church/more/history/commitment/History'));
const OrderDetails = lazy(() => import('../pages/church/more/history/order/Detail'));
const ContributionDetails = lazy(() => import('../pages/church/more/history/contribution/Detail'));
const CommitmentDetails = lazy(() => import('../pages/church/more/history/commitment/Detail'));
const DonationDetails = lazy(() => import('../pages/church/more/history/donation/Detail'));
const ProductDetails = lazy(() => import('../pages/church/financial/webshop/product/Detail'));
const NewsDetail = lazy(() => import('../pages/church/actual/news/Detail'));
const CommunityNewsDetail = lazy(() => import('../pages/church/actual/communitynews/Detail'));
const CharityDetails = lazy(() => import('../pages/church/financial/charity/Detail'));
const Donation = lazy(() => import('../pages/church/financial/charity/donation/Donation'));
const ShoppingBasket = lazy(() => import("../pages/church/financial/webshop/ShoppingBasket"));
const Contribution = lazy(() => import('../pages/church/financial/charity/contribution/Contribution'));
const Commitment = lazy(() => import('../pages/church/financial/charity/commitment/Commitment'));
const TopUpCredit = lazy(() => import('../pages/church/financial/webshop/TopUpCredit'));
const TransferCredit = lazy(() => import('../pages/church/financial/webshop/TransferCredit'));
const CalendarDetail = lazy(() => import('../pages/church/actual/calendar/Detail'));
const ContactDetails = lazy(() => import('../pages/church/community/contacts/Detail'));
const Order = lazy(() => import('../pages/church/financial/webshop/Order'));
const Error = lazy(() => import('./../pages/Error'));
const PaymentStatus = lazy(() => import('./../pages/PaymentStatus'));
const FAQ = lazy(() => import('../pages/church/more/faq/FAQ'));
const FAQDetails = lazy(() => import('../pages/church/more/faq/Detail'));
const FeedbackForm = lazy(() => import('../pages/church/more/feedback/FeedbackForm'));
const TopicDetails = lazy(() => import('../pages/church/community/forum/Detail'));
const TopicMembers = lazy(() => import('../pages/church/community/forum/Members'));
const DisclaimerInfo = lazy(() => import('../pages/church/more/about/DisclaimerInfo'));
const TermsAndConditionsInfo = lazy(() => import('../pages/church/more/about/TermsAndConditionsInfo'));
const PrivacyStatementInfo = lazy(() => import('../pages/church/more/about/PrivacyStatementInfo'));
const ValidateEmail = lazy(() => import('../pages/church/more/account/ValidateEmail'));
const RetryDonation = lazy(() => import('../pages/church/financial/retrypayments/RetryDonation'));
const RetryContribution = lazy(() => import('../pages/church/financial/retrypayments/RetryContribution'));
const RetryOrder = lazy(() => import('../pages/church/financial/retrypayments/RetryOrder'));
const DonationToOtherShop = lazy(() => import('../pages/church/financial/main/DonationToOtherShop'));

const Tabs: React.FC = (props) => {

    const [activeTab, setActiveTab] = useState<TabRoutes>(TabRoutes.Welcome);
    const {
        forum: {setForumMessageCount, forumMessageCount},
        shop: {forumEnabled, setForumEnabled, shopData, shopId},
        customer: {customerId, customerLocationId, authenticated, customerSessionToken}
    } = useGlobalState();
    const [pathName, setPathName] = useState("");
    const [active, setActive] = useState<boolean>(true);

    const interval: any = useRef();

    useEffect(() => {
        console.log('Add listener: appStateChange')
        App.addListener('appStateChange', ({isActive}) => {
            setActive(isActive);
        })
    }, []);

    useEffect(() => {
        clearInterval(interval.current);

        if (active) {
            interval.current = setInterval(() => {
                getTotalNewMessageCount();
            }, 30000)
        }
    }, [active])

    function getTotalNewMessageCount() {
        if (shopId !== null && customerId !== null && customerSessionToken !== null) {
            API.forum.getTotalNewMessageCount(customerSessionToken, shopId, customerLocationId).then((response) => {
                let count = response.data.count;
                let forumEnabled = response.data.forumEnabled ?? true;

                setForumEnabled(forumEnabled);
                setForumMessageCount(count);

                if (forumEnabled === false) {
                    clearInterval(interval.current);
                }
            }, (err) => {
                console.log(err.response);
                clearInterval(interval.current);
            });
        }
    }

    const checkPath = setInterval(() => {
        let temppath = window.location.pathname;
        if (temppath !== pathName) {
            setPathName(temppath);
        }
    }, 500);

    useEffect(() => {
        if (forumEnabled === true && authenticated !== null) {
            getTotalNewMessageCount();
        }
    }, [forumEnabled, authenticated])

    useEffect(() => {
        switch (true) {
            case pathName.indexOf(TabRoutes.Welcome) > -1:
                setActiveTab(TabRoutes.Welcome);
                break;
            case pathName.indexOf(TabRoutes.Actual) > -1:
                setActiveTab(TabRoutes.Actual);
                break;
            case pathName.indexOf(TabRoutes.Financial) > -1:
                setActiveTab(TabRoutes.Financial);
                break;
            case pathName.indexOf(TabRoutes.Community) > -1:
                setActiveTab(TabRoutes.Community);
                break;
            case pathName.indexOf(TabRoutes.More) > -1:
                setActiveTab(TabRoutes.More);
                break;
            default:
                setActiveTab(TabRoutes.Welcome);
        }
    }, [pathName])

    function setTab(event: any) {
        setActiveTab(event.detail.tab);
    }

    return (
        <IonReactRouter>
            <div className={"mainHeader"}>
                <img alt="shopLogo" className="shopLogo" src={shopData?.logo !== undefined && shopData?.logo !== null && shopData.logo !== false ? "https:" + shopData.logo : "/assets/logoAppostel-zonder-tekst.png"}/>

                <span className={"label"}>
            {shopData?.shortName !== undefined ? shopData.shortName : "Appostel"}
            </span>
            </div>
            <IonTabs onIonTabsDidChange={event => setTab(event)} className={"mainTabs"}>
                <IonRouterOutlet>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Welcome}`} component={Welcome}/>

                    {/*Actual*/}
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Actual}/tab`} component={Actual}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Actual}/calendar/:id(\\d+)`} component={CalendarDetail}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Actual}/news/:id(\\d+)`} component={NewsDetail}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Actual}/communitynews/:id(\\d+)`} component={CommunityNewsDetail}/>

                    {/*Financial*/}
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/tab`} component={Financial}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/product/:id(\\d+)`} component={ProductDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/charity/:id(\\d+)`} component={CharityDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/basket`} component={ShoppingBasket}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/order`} component={Order}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/donation`} component={Donation}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/charity/:id(\\d+)/contribution`} component={Contribution}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/charity/:id(\\d+)/commitment`} component={Commitment}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/charity/:id(\\d+)/donation`} component={Donation}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/topup`} component={TopUpCredit}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/transfercredit`} component={TransferCredit}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/transfercredit/:id(\\d+)`} component={TransferCredit}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/paymentstatus/:paymentdetails`} component={PaymentStatus}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/retrydonation/:donationId`} component={RetryDonation}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/retrycontribution/:contributionId`} component={RetryContribution}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/retryorder/:orderId`} component={RetryOrder}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Financial}/externaldonation`} component={DonationToOtherShop}/>


                    {/*Community*/}
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Community}/tab`} component={Community}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Community}/contact/:id(\\d+)`} component={ContactDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Community}/forum/topic/:id(\\d+)`} component={TopicDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.Community}/forum/topic/:id(\\d+)/members`} component={TopicMembers}/>

                    {/*More*/}
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/tab`} component={More}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/account`} component={AccountDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/account/edit`} component={EditAccountDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/account/changepassword`} component={ChangePassword}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/account/validateemail/:validationToken`} component={ValidateEmail}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/settings`} component={Settings}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/settings/credit`} component={BalanceSettings}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/bankaccount`} component={BankAccounts}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/bankaccount/:id(\\d+)`} component={BankAccountDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/bankaccount/:id(\\d+)/edit`} component={EditBankAccountDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/bankaccount/add`} component={AddBankAccount}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/appinfo`} component={AppInfo}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/credit`} component={BalanceHistory}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/credit/:id(\\d+)`} component={BalanceDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/order`} component={OrderHistory}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/order/:id(\\d+)`} component={OrderDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/contribution`} component={ContributionHistory}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/contribution/:id(\\d+)`} component={ContributionDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/donation`} component={DonationHistory}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/donation/:id(\\d+)`} component={DonationDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/commitment`} component={CommitmentHistory}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/history/commitment/:id(\\d+)`} component={CommitmentDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/faq`} component={FAQ}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/faq/:id(\\d+)`} component={FAQDetails}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/feedback`} component={FeedbackForm}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/disclaimer`} component={DisclaimerInfo}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/termsandconditions`} component={TermsAndConditionsInfo}/>
                    <Route path={`${TabRoutes.BaseRoute + TabRoutes.More}/privacy`} component={PrivacyStatementInfo}/>

                    <Route path={`${TabRoutes.BaseRoute}/error/:id`} component={Error}/>
                    <Route render={(props) => {
                        if (props.location.pathname === '/' + shopId || props.location.pathname === '/' + shopId + '/') {
                            window.location.href = '/' + shopId + '/welcome';
                        } else {
                            return <Error match={props.match} history={props.history} location={props.location} />
                        }
                    }}/>
                </IonRouterOutlet>
                <IonTabBar slot="bottom" color="light" selectedTab={activeTab} className={"footerMenu"}>
                    <IonTabButton tab={TabRoutes.Welcome} href={`/${shopId + TabRoutes.Welcome}`} aria-label="Start" className={"home" + (activeTab === TabRoutes.Welcome ? ' activeTab' : '')}>
                        <IonIcon src={'/assets/icon/home.svg'} style={{color: activeTab === TabRoutes.Welcome ? 'red' : 'rebeccapurple'}}/>
                        <span className={"label"} style={{color: activeTab === TabRoutes.Welcome ? 'red' : 'rebeccapurple'}}>Welkom</span>
                    </IonTabButton>
                    <IonTabButton tab={TabRoutes.Actual} href={`/${shopId + TabRoutes.Actual}/tab`} aria-label="Actualiteiten" className={"actual" + (activeTab === TabRoutes.Actual ? ' activeTab' : '')}>
                        <IonIcon src={'/assets/icon/actual.svg'} style={{color: activeTab === TabRoutes.Actual ? 'red' : 'rebeccapurple'}}/>
                        <span className={"label"} style={{color: activeTab === TabRoutes.Actual ? 'red' : 'rebeccapurple'}}>Actueel</span>
                    </IonTabButton>

                    <IonTabButton tab={TabRoutes.Financial} href={`/${shopId + TabRoutes.Financial}/tab`} aria-label="Financieel" className={"financial" + (activeTab === TabRoutes.Financial ? ' activeTab' : '')}>
                        <IonIcon src={'/assets/icon/financial.svg'} style={{color: activeTab === TabRoutes.Financial ? 'red' : 'rebeccapurple'}}/>
                        <span className={"label"} style={{color: activeTab === TabRoutes.Financial ? 'red' : 'rebeccapurple'}}>Financieel</span>
                    </IonTabButton>
                    {authenticated && (
                        <IonTabButton tab={TabRoutes.Community} href={`/${shopId + TabRoutes.Community}/tab`} aria-label="Gemeente" className={"contacts" + (activeTab === TabRoutes.Community ? ' activeTab' : '')}>
                            <IonIcon src={'/assets/icon/contacts.svg'} style={{color: activeTab === TabRoutes.Community ? 'red' : 'rebeccapurple'}}/>
                            <span className={"label"} style={{color: activeTab === TabRoutes.Community ? 'red' : 'rebeccapurple'}}>Gemeente</span>
                            {forumEnabled && forumMessageCount !== undefined && forumMessageCount! > 0 && (
                                <span className="badgeTab">{forumMessageCount}</span>
                            )}
                        </IonTabButton>
                    )}
                    <IonTabButton tab={TabRoutes.More} href={`/${shopId + TabRoutes.More}/tab`} aria-label="Overige" className={"more" + (activeTab === TabRoutes.More ? ' activeTab' : '')}>
                        <IonIcon src={'/assets/icon/more.svg'} style={{color: activeTab === TabRoutes.More ? 'red' : 'rebeccapurple'}}/>
                        <span className={"label"} style={{color: activeTab === TabRoutes.More ? 'red' : 'rebeccapurple'}}>Meer</span>
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>
            <div className="infoFooter">
                <a href={`/${shopId}/more/termsandconditions?return=${pathName}`}>Algemene voorwaarden</a>
                <a href={`/${shopId}/more/disclaimer?return=${pathName}`}>Disclaimer</a>
                <a href={`/${shopId}/more/privacy?return=${pathName}`}>Privacy verklaring</a>
                <a href={shopData?.adminUrl} className="end">Beheer</a>
            </div>
        </IonReactRouter>
    )
}

export default Tabs;